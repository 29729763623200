import { useState } from "react"

import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadisePager } from "src/components/Paradise/ParadisePager"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { ParadiseCreateTokensDialog } from "src/components/Paradise/ParadiseUsers/ParadiseUserDetails/ParadiseUserHomeTokens/ParadiseCreateTokensDialog"
import { ParadiseUserHomeTokenRow } from "src/components/Paradise/ParadiseUsers/ParadiseUserDetails/ParadiseUserHomeTokens/ParadiseuserHomeTokenRow"
import { ParadiseUserUpdateHomeTokenDialog } from "src/components/Paradise/ParadiseUsers/ParadiseUserDetails/ParadiseUserHomeTokens/ParadiseUserUpdateHomeTokenDialog"
import { Overview } from "src/components/Paradise/sharedStyles"
import { useFetchUserHomeTokens } from "src/data/homes/queries/homeTokenQueries"
import { THomeToken } from "src/data/homes/types/homeTokenTypes"
import { MText } from "src/ui/MText"

const LIMIT = 15

export function ParadiseUserHomeTokens({ userId }: { userId: string }) {
  const [offset, setOffset] = useState(0)
  const [showCreateHomeTokensDialog, setShowHomeTokensDialog] = useState(false)
  const [homeTokenToEdit, setHomeTokenToEdit] = useState<
    THomeToken | undefined
  >()

  const fetchedUserHomeTokens = useFetchUserHomeTokens({
    userId,
    filter: {
      limit: LIMIT,
      offset,
    },
    options: {
      keepPreviousData: true,
      retry: false,
    },
  })

  const headers = [
    <div key={"id"}>Id</div>,
    <div key={"home-id"}>Home id</div>,
    <div key={"created-at"}>Created at</div>,
    <div key={"first-renewal-after-activation"}>
      First renewal after activation
    </div>,
    <div key={"activated-at"}>Activated at</div>,
    <div key={"updated-at"}>Updated at</div>,
    <div key={"expires-at"}>Expires at</div>,
    <div key={"plan-type"}>Plan type</div>,
    <div key={"amount-paid"}>Amount paid</div>,
    <div key={"misc"}></div>,
  ]

  const rows =
    fetchedUserHomeTokens.data?.home_tokens.map((token) => (
      <ParadiseUserHomeTokenRow
        key={token.id}
        userId={userId}
        token={token}
        onEdit={() => setHomeTokenToEdit(token)}
      />
    )) ?? []

  return (
    <div>
      <ParadiseDetailsSection
        title="Home tokens"
        buttonLabel="Create home tokens"
        onButtonClick={() => setShowHomeTokensDialog(true)}
      />
      <Overview>
        <MText variant="nano">Total home tokens</MText>
        <MText variant="subtitleS">
          {fetchedUserHomeTokens.data?.paging.total_count}
        </MText>
      </Overview>

      <ParadiseTable
        header={headers}
        rows={rows}
        error={{
          hasError: fetchedUserHomeTokens.isError,
          title: fetchedUserHomeTokens.error?.message,
        }}
      />
      <ParadisePager
        limit={LIMIT}
        offset={offset}
        setOffset={setOffset}
        totalCount={fetchedUserHomeTokens.data?.paging.total_count}
      />
      <ParadiseCreateTokensDialog
        open={showCreateHomeTokensDialog}
        userId={userId}
        onClose={() => setShowHomeTokensDialog(false)}
      />
      {homeTokenToEdit && (
        <ParadiseUserUpdateHomeTokenDialog
          open={true}
          token={homeTokenToEdit}
          onClose={() => setHomeTokenToEdit(undefined)}
        />
      )}
    </div>
  )
}
